

import './App.css';

import MapComponent from '../mapComponent/MapComponent';

function App() {
	return (
		<div>
			<MapComponent />
		</div>

	);
}

export default App;
